import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import LoginForm from "../../Components/LoginForm";
import SettingForm from "../../Components/SettingForm";
import Waiting from "../../Components/Waiting";
import Ready from "../../Components/Ready";
import CourseSession from "../../Components/CourseSession";

const UiState = {
  LOGIN: "LOGIN",
  SETTING: "SETTING",
  WAITING: "WAITING",
  READY: "READY"
};

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UiState.LOGIN,
      startCourseSession: false,
      data: null,
      mirrorNumber: ""
    };
  }

  render() {
    let { profile, appActions } = this.props;
    let { uiState, startCourseSession, data, mirrorNumber } = this.state;

    return (
      <Wrapper>
        {startCourseSession ? (
          <CourseSession
            data={data}
            onClose={() =>
              this.setState({
                startCourseSession: false,
                uiState: UiState.WAITING
              })
            }
          />
        ) : (
          <>
            {this._showSettingButton()}
            <div className="container">
              <div className="top" />
              <div className="bottom">
                {(() => {
                  switch (uiState) {
                    case UiState.LOGIN:
                      return (
                        <LoginForm
                          onClose={() =>
                            this.setState({ uiState: UiState.SETTING })
                          }
                        />
                      );
                    case UiState.SETTING:
                      return (
                        <SettingForm
                          setMirror={number => {
                            if (number) {
                              window.localStorage.setItem(
                                "mirror-number",
                                number
                              );
                              this.setState({
                                uiState: UiState.WAITING,
                                mirrorNumber: number
                              });
                            }
                          }}
                          logout={() =>
                            appActions
                              .logout()
                              .then(() =>
                                this.setState({ uiState: UiState.LOGIN })
                              )
                              .catch(err => console.warn(err))
                          }
                        />
                      );
                    case UiState.WAITING:
                      return (
                        <Waiting
                          getOwner={data =>
                            this.setState({ data, uiState: UiState.READY })
                          }
                          mirrorNumber={mirrorNumber}
                        />
                      );
                    case UiState.READY:
                      return (
                        <Ready
                          data={data}
                          onClose={() =>
                            this.setState({ startCourseSession: true })
                          }
                        />
                      );
                  }
                })()}
              </div>
            </div>
          </>
        )}
      </Wrapper>
    );
  }

  _showSettingButton = () => {
    let { uiState } = this.state;

    if (uiState === UiState.WAITING || uiState === UiState.READY) {
      return (
        <button
          className="setting"
          onClick={() => this.setState({ uiState: UiState.SETTING })}
        >
          設定
        </button>
      );
    }

    return;
  };
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  background-image: url("/images/pages/home/bgImage.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1550px;
    width: 100%;

    & > .top {
      width: 387px;
      height: 367px;

      background-image: url("/images/pages/home/logo.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    & > .bottom {
      flex: 0.5;
      min-width: 650px;
      min-height: 450px;
      margin-top: 150px;
      padding: 50px 100px;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  & > .setting {
    position: absolute;
    top: 50px;
    right: 50px;

    min-width: 100px;
    border: 0px;
    border-radius: 25px;
    padding: 8px 10px;
    background-color: black;
    color: white;
    cursor: pointer;

    :focus {
      outline: none;
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(HomePage)
);
