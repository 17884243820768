import React, { Component } from "react";
import styled from "styled-components";
import RemoteFrame from "./RemoteFrame";

class CourseMirror extends Component {
  render() {
    return (
      <Wrapper>
        {/* <div className="hint">請保持肩膀水平</div> */}
        <RemoteFrame
          wsUrl={"http://localhost:4141"}
          width={1080} //todo: make sure screen size to fulfill, now is 1440*900
          height={1920}
          debug={false}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  & > .hint {
    margin-top: 40px;
    padding: 20px;
    background: rgba(89, 180, 179, 0.5);
    max-width: 500px;
    width: 100%;
    text-align: center;
    color: white;
  }
`;

export default CourseMirror;
