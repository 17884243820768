import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "../Components/Widget2";

class SettingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: ""
    };
  }

  render() {
    let { setMirror, logout } = this.props;
    let { number } = this.state;

    return (
      <Wrapper>
        <Widget.FormTitle css="margin-bottom: 60px;">設定</Widget.FormTitle>
        <Container css="border-bottom: 1px solid white; margin-bottom: 30px;">
          <Widget.Row align="center">
            <span style={{ marginRight: 26 }}>此螢幕為</span>
            <input
              value={number}
              onChange={e => this.setState({ number: e.target.value })}
            />
            <span style={{ marginLeft: 26 }}>號健身鏡</span>
          </Widget.Row>
          <div className="button">
            <Widget.FormButton onClick={() => setMirror(number)}>
              確定
            </Widget.FormButton>
          </div>
        </Container>
        <Container>
          <div className="button">
            <Widget.FormButton onClick={logout}>登出</Widget.FormButton>
          </div>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

const Container = styled.div`
  & > div {
    margin-bottom: 30px;
    & > span {
      letter-spacing: -0.15px;
      font-size: 28px;
      color: white;
    }

    & > input {
      border: 0px;
      border-radius: 43.5px;
      padding: 8px;
      max-width: 120px;
      width: 100%;
      text-align: center;
      font-size: 28px;
      color: #404040;

      :focus {
        outline: none;
      }
    }
  }

  & > .button {
    display: flex;
    justify-content: flex-end;
  }

  ${props => props.css || ""};
`;

export default SettingForm;
