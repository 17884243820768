import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";

class CourseInfo extends Component {
  render() {
    let { data, finishTime, videoDuration, videoTime } = this.props;
    let time = data.course.time_span;

    return (
      <Wrapper>
        <div className="row">
          <span>{`課程名稱：${data && data.course.name}`}</span>
          <span>{`課程時長：${videoDuration || "--分--秒"}`}</span>
        </div>
        <div className="row">
          <span>{`當前時間：${this._renderCurrentTime()}`}</span>
          <span>{`預計完成時間：${finishTime || "--:--"}`}</span>
        </div>
        <div className="row">
          <span>
            課程剩餘時間：
            <span className="timer">{videoTime || "--分--秒"}</span>
          </span>
        </div>
        <img className="logo" src="/images/pages/home/logo.png" />
      </Wrapper>
    );
  }

  _timeFormat = time => {
    if (typeof time === "number") {
      time = time.toString();

      if (time.length === 1) {
        return `0${time}`;
      }
    }
    return time;
  };

  _renderCurrentTime = () => {
    let now = new Date();
    let nowHours = this._timeFormat(now.getHours());
    let nowMins = this._timeFormat(now.getMinutes());

    let currentTime = `${nowHours}:${nowMins}`;

    return currentTime;
  };
}

const Wrapper = styled.div`
  position: relative;
  padding: 80px;
  width: 100%;
  height: 100%;

  & > .row {
    display: flex;
    justify-content: flex-start;
    & > span {
      margin-right: 60px;
      margin-bottom: 20px;
      min-width: 270px;
      font-size: 28px;
      color: white;
    }

    & > span .timer {
      margin: 0px 40px;
      font-weight: 600;
      font-size: 60px;
      color: #59b4b3;
    }
  }

  & > .logo {
    position: absolute;
    width: 100px;
    height: 95px;
    right: 20px;
    bottom: 25px;
  }
`;

export default CourseInfo;
