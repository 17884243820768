import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import * as Widget from "../Components/Widget2";
import QRCode from "qrcode.react";

class Waiting extends Component {
  componentDidMount() {
    let { appActions, getOwner } = this.props;
    this.timer = setInterval(() => {
      appActions
        .getMirrorStatus()
        .then(data => {
          if (data[0].owner.id) {
            getOwner(data[0]);
          }
          return;
        })
        .catch(err => console.warn(err));
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    let { mirrorNumber } = this.props;
    return (
      <Wrapper>
        <Widget.Hint>等待使用者上課中...</Widget.Hint>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "inline-flex",
              backgroundColor: "white",
              padding: 10,
              margin: 20
            }}
          >
            <QRCode value={mirrorNumber} />
          </div>
        </div>

        <Widget.Hint css="font-size: 30px;">
          請將手機螢幕對準此QR code
        </Widget.Hint>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

export default connect(
  null,
  ActionCreator
)(Waiting);
