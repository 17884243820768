import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";

class CourseVideo extends Component {
  render() {
    let { data } = this.props;

    return (
      <Wrapper>
        <ReactPlayer
          youtubeConfig={{ playerVars: { start: 0, controls: 0 } }}
          url={data.course.url}
          ref={this._ref}
          width={"100%"}
          height={"100%"}
          playing
          controls={true}
          onReady={() => {
            this._passCourseDuration();
            this._passExpectedFinishTime();
          }}
          onPlay={() => {
            this.timer = setInterval(this._timing, 1000);
            return this.timer;
          }}
          onEnded={() => this._endCourseSession()}
        />
      </Wrapper>
    );
  }

  _ref = player => {
    this.player = player;
  };

  _timeFormat = time => {
    if (typeof time === "number") {
      time = time.toString();

      if (time.length === 1) {
        return `0${time}`;
      }
    }
    return time;
  };

  _passCourseDuration = () => {
    let { videoDuration } = this.props;

    let time = this.player && this.player.getDuration();
    let timeMin = Math.floor(time / 60);
    let timeSec = Math.floor((time % 60) - 0.01);

    return videoDuration(`${timeMin}分${timeSec}秒`);
  };

  _passExpectedFinishTime = () => {
    let { finishTime } = this.props;

    let now = Math.floor(new Date().getTime());
    let duration = Math.floor(this.player.getDuration() * 1000);
    let finish = new Date(now + duration);

    let formatHour = this._timeFormat(finish.getHours());
    let formatMin = this._timeFormat(finish.getMinutes());

    return finishTime(`${formatHour}:${formatMin}`);
  };

  _timing = () => {
    let { videoTime } = this.props;

    let duration = Math.floor(this.player && this.player.getDuration());
    let current = Math.floor(this.player && this.player.getCurrentTime());

    let remainingMin = Math.floor((duration - current) / 60);
    let remainingSec = Math.floor((duration - current) % 60);

    return videoTime(`${remainingMin}分${remainingSec}秒`);
  };

  _endCourseSession = () => {
    let { appActions, data, onClose } = this.props;

    clearInterval(this._timer);
    appActions
      .endCourseSession(data.id)
      .then(result => onClose())
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export default connect(
  null,
  ActionCreator
)(CourseVideo);
