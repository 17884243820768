import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import * as Widget from "../Components/Widget2";

class Ready extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 3
    };
  }

  componentDidMount() {
    let { onClose } = this.props;

    this.countdownTimer = setInterval(() => {
      let { seconds } = this.state;
      if (seconds === 0) {
        clearInterval(this.countdownTimer);
        onClose();
      } else {
        this.setState({ seconds: seconds - 1 });
      }
    }, 1000);
  }

  render() {
    let { data } = this.props;
    let { seconds } = this.state;

    return (
      <Wrapper>
        <Widget.Row align="center" css="margin-bottom: 20px;">
          <Widget.Hint css="margin-right: 20px;">{`${data.owner.name ||
            "---"} 您好`}</Widget.Hint>
          <img src="/images/icon01.png" style={{ width: 70, height: 95 }} />
        </Widget.Row>
        <Widget.Hint css="margin-bottom: 30px;">
          {`準備開始${data.course.name || "---"}課程`}
        </Widget.Hint>
        <div className="count-down">{seconds}</div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & > .count-down {
    text-align: center;
    font-weight: 600;
    font-size: 200px;
    color: #59b4b3;
    line-height: 200px;
  }
`;

export default connect(
  null,
  ActionCreator
)(Ready);
