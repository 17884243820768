import React, { Component } from "react";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import CourseInfo from "./CourseInfo";
import CourseVideo from "./CourseVideo";
import CourseMirror from "./CourseMirror";

class CourseSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finishTime: 0,
      videoDuration: 0,
      videoTime: 0
    };
  }

  componentDidMount() {
    this.timer = setInterval(async () => {
      let { appActions, onClose } = this.props;
      try {
        let mirrors = await appActions.getMirrorStatus();
        let mirror = mirrors[0];

        if (!mirror.owner || !mirror.course) {
          onClose();
        }
      } catch (ex) {
        // bypass
      }
    }, 3000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  render() {
    let { data, onClose } = this.props;
    let { finishTime, videoDuration, videoTime } = this.state;
    return (
      <Wrapper>
        {data.course.url ? (
          <div className="container">
            <div className="course-info">
              <CourseInfo
                data={data}
                finishTime={finishTime}
                videoDuration={videoDuration}
                videoTime={videoTime}
              />
            </div>
            <div className="mirror">
              {/* <CourseMirror /> */}{" "}
              {/* It's a black screen for being a mirror (issue #25) */}
            </div>
            <div className="course-video">
              <CourseVideo
                data={data}
                onClose={onClose}
                finishTime={time => this.setState({ finishTime: time })}
                videoDuration={time => this.setState({ videoDuration: time })}
                videoTime={time => this.setState({ videoTime: time })}
              />
            </div>
          </div>
        ) : (
          <CourseMirror />
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;

  & > .container {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > .course-info {
      background-color: lightgray;
      background-image: url("/images/pages/home/bgImage02.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
    }

    & > .course-video {
      width: 100%;
      align-self: stretch;
      flex: 1;
    }

    & > .mirror {
      background-color: black;
      flex: 1;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(CourseSession);
