import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import * as Unstated from "../Utils/Unstated";
import * as Widget from "../Components/Widget2";

class LoginForm extends Component {
  render() {
    let { onClose, appActions } = this.props;

    return (
      <Wrapper>
        <Unstated.Form
          key="login"
          init={() => ({
            values: { username: "", password: "" },
            editing: true
          })}
          submit={values => {
            if (!values.username || !values.password) {
              return Promise.resolve({
                values,
                error: "所有欄位皆須填寫!"
              });
            }

            let { username, password } = values;
            return appActions
              .login({ username, password })
              .then(onClose)
              .then(() => {
                appActions.ga.trackingUserAction("User", "login", "success");
              })
              .catch(err => {
                if (err && err.status === 401) {
                  appActions.ga.trackingUserAction("User", "login", "fail 401");
                  return { error: "帳號密碼錯誤！" };
                }
                appActions.ga.trackingUserAction(
                  "User",
                  "login",
                  "fail others"
                );
                return { error: "發生錯誤！" };
              });
          }}
        >
          {({ values, errors, inputProps, editProps, submitProps }) => (
            <>
              <Widget.FormTitle css="margin-bottom: 60px;">
                登入
              </Widget.FormTitle>
              <div className="form">
                <span>帳號</span>
                <input {...inputProps.username} />
              </div>
              <div className="form">
                <span>密碼</span>
                <input type="password" {...inputProps.password} />
              </div>
              {submitProps.submitError && (
                <div className="error">{submitProps.submitError}</div>
              )}
              <Widget.FormButton
                onClick={submitProps.submit}
                css="float: right;"
              >
                登入
              </Widget.FormButton>
            </>
          )}
        </Unstated.Form>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & > .form {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    & > span {
      margin-right: 26px;
      letter-spacing: -0.18px;
      font-size: 28px;
      color: white;
    }

    & > input {
      flex: 1;
      border: 0px;
      border-radius: 43.5px;
      padding: 8px 20px;

      font-size: 28px;
      color: #404040;

      :focus {
        outline: none;
      }
    }
  }

  & > .error {
    margin-bottom: 20px;
    text-align: right;
    font-weight: 300;
    color: #ff4d00;
  }

  & > button {
    float: right;
    border: 0px;
    border-radius: 25px;
    padding: 8px 10px;
    min-width: 120px;
    background-color: #59b4b3;
    text-align: center;
    font-size: 28px;
    color: white;
    cursor: pointer;

    :focus {
      outline: none;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(LoginForm);
