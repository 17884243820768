import React from "react";
import SocketIoClient from "socket.io-client";

const AppEventPrefix = "app";
const FSClientState = {
  idle: 0,
  connecting: 1,
  connected: 2,
  disconnecting: 3
};

class FSClient {
  constructor({ debug }) {
    this.state = FSClientState.idle;
    this.debug = debug;
  }

  connect(url, onNextFrameCallback, options = { fps: 1 }) {
    if (this.state !== FSClientState.idle) {
      return;
    }

    this.socket = SocketIoClient(url);
    this.state = FSClientState.connecting;
    this.onNextFrameCallback = onNextFrameCallback;
    this.dispatch = (type, payload) => {
      this.socket.emit(AppEventPrefix, { type, payload });
    };

    this.socket.on("connect", () => {
      this.state = FSClientState.connected;
      this.socket.on(AppEventPrefix, this._handleAction);
      this._requestNextFrame();
      this.timer = setInterval(this._requestNextFrame, 1000 / options.fps);
    });

    this.socket.on("disconnect", () => {
      clearTimeout(this.timer);
      this.timer = null;
      this.socket.off(AppEventPrefix, this._handleAction);
      this.state = FSClientState.idle;
      this.socket = null;
    });
  }

  disconnect() {
    this.socket.disconnect();
  }

  _handleAction = ({ type, payload } = {}) => {
    if (this.debug) {
      console.log("----------");
      console.log("type", type);
      console.log("socket", this.socket.id);
      console.log("timestamp", new Date().getTime());
      console.log("----------");
    }

    switch (type) {
      case "frame-data":
        if (this.onNextFrameCallback) {
          this.onNextFrameCallback(payload);
        }
        break;
    }
  };

  _requestNextFrame = () => {
    if (this.state === FSClientState.connected) {
      this.dispatch("get-frame", null);
    }
  };
}

class RemoteFrame extends React.Component {
  constructor(props) {
    super(props);
    let { debug } = this.props;
    // this.fsClient = new FSClient({ debug });
    this.state = {};
  }

  /*
  componentDidMount() {
    let { debug, wsUrl } = this.props;
    console.log(wsUrl);
    this.fsClient.connect(wsUrl, data => {
      if (debug) {
        console.log(">>> next frame", data && data.length);
      }
      let element = document.getElementById("rev-remote-frame");
      if (typeof data === "string") {
        // assume it's a base64-png
        element.src = data;
      } else {
        // assum it's a ArrayBuffer
        let blob = new Blob([new Uint8Array(data.slice(0, data.length))]);
        let objUrl = URL.createObjectURL(blob);
        element.src = objUrl;
      }
    });
  }
  */

  /*
  componentWillUnmount() {
    this.fsClient.disconnect();
  }
  */

  render() {
    let { width, height } = this.props;

    return (
      <div
        style={{
          width,
          height,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img
          style={{ width }}
          id="rev-remote-frame"
          src="http://localhost:8000/video/"
        />
      </div>
    );
  }
}

export default RemoteFrame;
